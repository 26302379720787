import React from 'react'
import { ButtonGroup } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import { Add, Delete } from '@mui/icons-material'
import GenericTablePage from '@templates/Generic/Table'
import { deleteCredentials, getCredentials } from '@utils/api/credentials'
import useApi, { wasSuccess } from '@hooks/useApi'
import { navigate } from 'gatsby'

const columns = [
  {
    field: 'credentials_id',
    headerName: 'Credential Id',
    width: 180,
    type: 'enhancedString'
  },
  {
    field: 'credentials_type',
    headerName: 'Credential Type',
    width: 180,
    type: 'enhancedString'
  }
]

const HostsCredentialsPage = () => {
  const api = useApi({ apiMethod: getCredentials })

  const deleteApi = useApi<any>({
    apiMethod: deleteCredentials,
    requestOnMount: false
  })

  return (
    <GenericTablePage
      keyProp="id"
      api={api}
      title="Credentials"
      maxWidth="md"
      breadcrumbs={[
        {
          title: 'Hosts',
          to: '/hosts/'
        },
        {
          title: 'Credentials'
        }
      ]}
      gridOptions={{
        columns,
        onRowClick: (param: { id: string }) => {
          navigate(`/hosts/credentials/edit/${param.id}/`)
        }
      }}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button endIcon={<Add />} to="/hosts/credentials/add/">
            Add Credential
          </Button>
        </ButtonGroup>
      }
      bulkActions={[
        {
          text: 'Delete Selected',
          icon: <Delete fontSize="small" />,
          action: async (selection: string[]) => {
            await deleteApi.makeRequest(selection).then((res) => {
              if (wasSuccess(res)) {
                deleteApi.enqueueSnackbar('Credentials successfully deleted.', {
                  variant: 'success'
                })
              }
            })
          },
          autoRefresh: true
        }
      ]}
    />
  )
}

export default HostsCredentialsPage
